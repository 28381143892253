import { metaMask } from "../connectors/metaMask";
import { walletConnect } from "../connectors/walletConnect";
import { EnumWallet } from "../constants";

export async function connectWallet(type: EnumWallet, slient = false) {
  let connector;

  if (type === EnumWallet.Metamask) {
    connector = metaMask;
  } else if (type === EnumWallet.WalletConnect) {
    connector = walletConnect;
  }

  if (connector) {
    try {
      if (slient) {
        await connector.connectEagerly();
      } else {
        await connector.activate();
      }
    } catch (error) {
      console.error("connectWallet", { error });
    }
  }
}
