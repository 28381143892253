import axios from "axios";
import Cookies from "js-cookie";
import useSWR from "swr";

const regionHttp = axios.create();
const http = axios.create();

http.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";
http.defaults.timeout = 10000; // 请求的超时时间
http.defaults.withCredentials = false; // 需要跨域携带认证

// 添加拦截器请求,在所有请求前拦截，做需要的操作
http.interceptors.request.use(
  (config) => {
    const logininfostr = Cookies.get("logininfo") || "";
    const logininfo = logininfostr ? JSON.parse(logininfostr) : {};
    config.headers.common["x-cryptonatty-token"] = logininfo.token || "";
    config.headers.common["Content-Type"] = "application/json;charset=utf-8;";
    return config;
  },
  (err) => Promise.reject(err)
);

// 响应拦截器
http.interceptors.response.use(
  ({ data }) => {
    // 在这里对返回的数据进行处理
    return Promise.resolve([data, null]);
  },
  (err) => {
    return Promise.resolve([null, err]);
  }
);
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST;

const base = API_HOST + "/apis/v1alpha1";

http.defaults.baseURL = base; // 发送请求的基础url

export const api = {
  base: {
    overview: base + "/overview",
    loginNonce: base + "/login/nonce",
    login: base + "/login",
    logout: base + "/logout",
    profile: base + "/user/profile",
    profileSave: base + "/user/profile/save",
    nftList: base + "/nft/list",
    contract: base + "/contract",
    discordBind: base + "/discord/bind",
    discordUnbind: base + "/discord/unbind",
    nftGet: base + "/nft/get",
    rewardList: base + "/reward/list",
    rewardClaimVip: base + "/reward/claim_vip",
    rewardClaimVoucher: base + "/reward/claim_voucher",
    rewardClaimPresentBox: base + "/reward/present_box",
    rewardConfirm: base + "/reward/confirm",
    rewardSummary: base + "/reward/summary",
    editRecipientAddr: base + "/reward/edit_recipient_addr",
    emojiMergeResult: base + "/emoji/merge_result",
    emojiMergeList: base + "/emoji/merge_list",
    authorizationList: base + "/authorization/list",
    authorizationChangeBind: base + "/authorization/change_bind",
    lotteryBind: base + "/authorization/lottery/bind",
    lotteryList: base + "/authorization/lottery/list",
    goodList: base + "/mall/list_goods",
    goodExchange: base + "/mall/goods_exchange",
    userPoints: base + "/points/user",
    rememberedInfo: base + "/mall/remembered_info",
    getOwnPoints: base + "/points/user",
    claimRecoupPoints: base + "/points/compensation/claim",
    pointsBill: base + "/points/receipt",
    archiveUnbrewPoints: base + "/points/archive",
    getUnbrewReceipt: base + "/points/receipt_detail",
    goodsPostage: base + "/mall/goods_postage",
    goodsOrder: base + "/mall/goods_order",
    goodsLimit: base + "/mall/goods_limit",
    bilibiligettoken: base + "/bilibili/get_token",
    bilibilibind: base + "/bilibili/bind",
    bilibiliunbind: base + "/bilibili/unbind",
  },
};

/**
 *  函数的参数options为axios的配置
 *  method => 方法名 "POST"等
 *  url => 路径,实际调用时传type即可，即为urlDict的key
 *  data => 数据的对象
 *  调用前将type值转为对应的url
 */
function request(options: any): Promise<any> {
  return new Promise((resolve) => {
    options.headers = options.headers || {};
    options.method = options.method || "get";
    if (options.method.toUpperCase() === "GET") {
      options.params = options.data;
    }
    return http(options)
      .then((res) => {
        resolve({ data: res[0], err: res[1] });
      })
      .catch(([data, err]) => {
        resolve({ data, err });
      });
  });
}

export class DeniedError extends Error {
  constructor() {
    super("denied by region_block");
    this.name = "DeniedError";
  }
}
export const overviewApi = () => {
  return regionHttp({
    url: api.base.overview,
    method: "post",
  }).catch((err) => {
    if (err.response?.headers["x-tengine-error"] === "denied by region_block") {
      return Promise.reject(new DeniedError());
    }
    return Promise.resolve();
  });
};

export const loginApi = (data) => {
  return request({
    data,
    url: api.base.login,
    method: "post",
  });
};

export const logoutApi = () => {
  return request({
    url: api.base.logout,
    method: "post",
  });
};

export const loginNonceApi = (data) => {
  return request({
    data,
    url: api.base.loginNonce,
    method: "post",
  });
};

export const fetcher = (url) => http.post(url).then((res) => res.data);

export const profileApi = () => {
  return request({
    url: api.base.profile,
    method: "post",
  });
};

export function GetProfile() {
  const logininfostr = Cookies.get("logininfo") || "";
  const logininfo = logininfostr ? JSON.parse(logininfostr) : {};
  const { data, mutate } = useSWR(
    logininfo.connectWay ? api.base.profile : null,
    profileApi
  );
  return { data, mutate };
}

export const profileSaveApi = (data) => {
  return request({
    data,
    url: api.base.profileSave,
    method: "post",
  });
};

export const nftListApi = () => {
  return request({
    url: api.base.nftList,
    method: "post",
  });
};

export const contract = (data) => {
  return request({
    url: api.base.contract,
    method: "post",
    data,
  });
};

export const discordBind = (data) => {
  return request({
    url: api.base.discordBind,
    method: "post",
    data,
  });
};

export const discordUnbind = () => {
  return request({
    url: api.base.discordUnbind,
    method: "post",
  });
};

export const nftGet = (data) => {
  return request({
    url: api.base.nftGet,
    method: "post",
    data,
  });
};

export const rewardList = (data) => {
  return request({
    url: api.base.rewardList,
    method: "post",
    data,
  });
};

export const rewardClaimVip = (data) => {
  return request({
    url: api.base.rewardClaimVip,
    method: "post",
    data,
  });
};

export const rewardClaimVoucher = (data) => {
  return request({
    url: api.base.rewardClaimVoucher,
    method: "post",
    data,
  });
};

export const rewardPresentBox = (data) => {
  return request({
    url: api.base.rewardClaimPresentBox,
    method: "post",
    data,
  });
};

export const rewardConfirm = (data) => {
  return request({
    url: api.base.rewardConfirm,
    method: "post",
    data,
  });
};

export const rewardSummary = (data) => {
  return request({
    url: api.base.rewardSummary,
    method: "post",
    data,
  });
};

export const editRecipientAddr = (data) => {
  return request({
    url: api.base.editRecipientAddr,
    method: "post",
    data,
  });
};

export const emojiMergeResult = (data) => {
  return request({
    url: api.base.emojiMergeResult,
    method: "post",
    data,
  });
};

export const emojiMergeList = (data) => {
  return request({
    url: api.base.emojiMergeList,
    method: "post",
    data,
  });
};

export const authorizationChangeBind = (data) => {
  return request({
    url: api.base.authorizationChangeBind,
    method: "post",
    data,
  });
};

export const authorizationList = () => {
  return request({
    url: api.base.authorizationList,
    method: "post",
  });
};

export const lotteryBind = (data) => {
  return request({
    url: api.base.lotteryBind,
    method: "post",
    data,
  });
};

export const lotteryList = () => {
  return request({
    url: api.base.lotteryList,
    method: "post",
  });
};

export const userPointsApi = () => {
  return request({
    url: api.base.userPoints,
    method: "post",
    data: {},
  });
};

export const goodListApi = () => {
  return request({
    url: api.base.goodList,
    method: "post",
    data: {},
  });
};

export const goodExchangeApi = (data) => {
  return request({
    url: api.base.goodExchange,
    method: "post",
    data,
  });
};

export const rememberedInfoApi = () => {
  return request({
    url: api.base.rememberedInfo,
    method: "post",
    data: {},
  });
};

export const getOwnPoints = () => {
  return request({
    url: api.base.getOwnPoints,
    method: "post",
    data: {},
  });
};

export const claimRecoupPoints = () => {
  return request({
    url: api.base.claimRecoupPoints,
    method: "post",
    data: {},
  });
};

export const getPointsBill = () => {
  return request({
    url: api.base.pointsBill,
    method: "post",
    data: {},
  });
};

export const archiveUnbrewPoints = () => {
  return request({
    url: api.base.archiveUnbrewPoints,
    method: "post",
    data: {},
  });
};

export const getUnbrewReceipt = (receiptId: number | string) => {
  return request({
    url: api.base.getUnbrewReceipt,
    method: "post",
    data: {
      receipt_id: receiptId,
    },
  });
};

export const goodsPostageApi = (data) => {
  return request({
    url: api.base.goodsPostage,
    method: "post",
    data,
  });
};

export const goodsOrderApi = (receiptId: string | number) => {
  return request({
    url: api.base.goodsOrder,
    method: "post",
    data: {
      receiptId,
    },
  });
};

export const goodsLimitApi = (data) => {
  return request({
    url: api.base.goodsLimit,
    method: "post",
    data,
  });
};

// 绑定哔哩哔哩 获取验证码
export const postbilibilitoken = (data) => {
  return request({
    url: api.base.bilibiligettoken,
    method: "post",
    data,
  });
};
// 绑定哔哩哔哩 绑定
export const postbilibilibind = (data) => {
  return request({
    url: api.base.bilibilibind,
    method: "post",
    data,
  });
};
// 绑定哔哩哔哩 绑定
export const postbilibiliunbind = (data) => {
  return request({
    url: api.base.bilibiliunbind,
    method: "post",
    data,
  });
};
