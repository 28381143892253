export const themeOptions = {
  palette: {
    primary: {
      main: 'linear-gradient(90deg, #0096FF 0%, #BC2AEF 50.83%, #ff2800 100%)',
      hover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #0096FF 0%, #BC2AEF 50.83%, #ff2800 100%)',
    },
    secondary: {
      main: '#000',
    },
    info: {
      main: '#FFF',
      hover:'#E3E5E7'
    },
    hoverColor: {
      main: '#ff2800',
    },
    text: {
      primary: 'rgba(255,0,0,0.87)',
    },
  },
};
