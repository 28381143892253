import { NavbarV2 } from "./navbar-v2";
import { FooterV2 } from "./FooterV2";
import { useRouter } from "next/router";
import { useWindowHeight } from "../hooks";
import { useEffect, useState } from "react";

export default function Layout({ children }) {
  const router = useRouter();
  const { windowHeight } = useWindowHeight();
  const [footerFixed, setFooterFixed] = useState(false);

  const enableAnimate = ["/", "/points", "/cheersup"].includes(router.pathname);

  const showWhiteLine = footerFixed || router.pathname.includes("/cheersup");

  useEffect(() => {
    const _footerFixed = [
      "/",
      "/404",
      "/denied",
      "/mall",
      "/points",
      "/cheersup",
    ].includes(router.pathname);

    if (router.pathname === "/cheersup/roadmap") {
      setFooterFixed(windowHeight >= 900);
    } else {
      setFooterFixed(_footerFixed);
    }
  }, [windowHeight, router]);

  return (
    <>
      <NavbarV2 enableAnimate={enableAnimate} showWhiteLine={showWhiteLine} />
      <main>{children}</main>
      <FooterV2
        footerFixed={footerFixed}
        enableAnimate={enableAnimate}
        showWhiteLine={showWhiteLine}
      />
    </>
  );
}
