import React from "react";
import { SnackbarProvider } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
//import Slide from '@mui/material/Slide';
import Slide from "./Transitions/Slide";
export interface MessageTipProps {
  children: JSX.Element | JSX.Element[];
}
const icons = {
  default: (
    <img src={"/icon/icon-info.svg"} className={"w-[20px] h-[20px] mr-[9px]"} />
  ),
  error: (
    <img
      src={"/icon/icon-error.svg"}
      className={"w-[20px] h-[20px] mr-[9px]"}
    />
  ),
  success: (
    <img
      src={"/icon/icon-success.svg"}
      className={"w-[20px] h-[20px] mr-[9px]"}
    />
  ),
  warning: (
    <img
      src={"/icon/icon-error.svg"}
      className={"w-[20px] h-[20px] mr-[9px]"}
    />
  ),
  info: (
    <img src={"/icon/icon-info.svg"} className={"w-[20px] h-[20px] mr-[9px]"} />
  ),
  loading: (
    <CircularProgress
      size={20}
      color={"inherit"}
      className={"w-[20px] h-[20px] mr-[9px]"}
    ></CircularProgress>
  ),
};
export default function MessageTip(props: MessageTipProps) {
  const { children } = props;
  return (
    // @ts-ignore
    <SnackbarProvider
      iconVariant={icons}
      classes={{
        variantSuccess: "variantbg",
        variantError: "variantbg",
        variantWarning: "variantbg",
        variantInfo: "variantbg",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      maxSnack={3}
      autoHideDuration={3000}
      TransitionComponent={Slide}
      className={"relative top-[110px]"}
    >
      {children}
    </SnackbarProvider>
  );
}
