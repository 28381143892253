import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { throttle } from "throttle-debounce";

export default function useNavbgc() {
  const initBg =
    "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.15) 48.44%, rgba(0, 0, 0, 0) 100%)";

  const router = useRouter();
  const [navBgc, setNavBgc] = useState(initBg);

  const computeNavBgc = function () {
    const newNavBgc = window.scrollY > 100 ? "black" : initBg;
    // console.log("newNavBgc", newNavBgc);
    setNavBgc(newNavBgc);
  };

  const computeNavBgcThrottle = throttle(50, computeNavBgc);

  useEffect(() => {
    // console.log("useEffect 2", router);
    if (router.pathname === "/cheersup/about") {
      computeNavBgc();
      window.addEventListener("scroll", computeNavBgcThrottle);
      return () => {
        window.removeEventListener("scroll", computeNavBgcThrottle);
      };
    } else {
      setNavBgc("black");
    }
  });

  return navBgc;
}
