import * as React from "react";
import { useEffect } from "react";
import Image from "next/legacy/image";
import Wallet from "../Modal/wallet";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useWeb3React } from "@web3-react/core";
import Link from "next/link";
import Button from "../UI/Button";
import {
  GetProfile,
  loginNonceApi,
  loginApi,
  logoutApi,
} from "../../utils/api";
import { useSnackbar } from "notistack";
import useNavbgc from "../useNavBgc-v2";
import { utils } from "ethers";
import { formatThousand, getLoginInfo, scrollToY } from "../../utils/util";
import {
  CHAINREQUEST,
  CHAIN_IDS,
  EnumWallet,
  LAUNCH_TOWER_URL,
  USE_CHAIN_ID,
} from "../../constants";
import { connectWallet } from "../../utils/connectWallet";
import { LocaleSwitcherV2 } from "../LocaleSwitcherV2";
import classNames from "classnames";
import { ColorLink } from "../index-v2/color-link";
import { BorderButton } from "../UI/BorderButton";
import { NavbarMenuIcon } from "./navbar-menu-icon";
import { MenuMask } from "./menu-mask";
import { useMs } from "@/hooks/useMs";
import { useTranslation } from "next-i18next";
import { useHistory } from "@/hooks/useHistory";

export function NavbarV2({ enableAnimate = false, showWhiteLine = false }) {
  const {
    connector,
    account,
    provider,
    isActive,
    chainId,
    error: web3Error,
  } = useWeb3React();
  // console.log("useWeb3React", {
  //   connector,
  //   account,
  //   provider,
  //   isActive,
  //   chainId,
  // });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");
  const [wvisabvle, setwvisabvle] = React.useState(false);
  const [info, setinfo] = React.useState(null);
  const [web3balance, setweb3Balance] = React.useState("");
  const [timer, settimer] = React.useState(null);
  const [connectWay, setConnectWay] = React.useState(EnumWallet.None);
  const router = useRouter();
  const navBgc = useNavbgc();
  const initialized = React.useRef(false);
  const [menuMaskOpen, setMenuMaskOpen] = React.useState(false);
  const { ms } = useMs({ maxMs: 5000 });
  const history = useHistory();
  const { locale } = router;

  const { data: profileData } = GetProfile();
  const profile = profileData?.data?.profile;

  const isLoggedIn = !!profile?.name;

  // console.log("isLoggedIn", isLoggedIn);

  // console.log("router", router);

  // console.log("info profile", { info, data, profile });

  const logout = async (noRedirect = false) => {
    await connector?.deactivate();
    if (timer) clearInterval(timer);
    setinfo(null);
    logoutApi().then(() => {
      location.href = "/api/logout" + (noRedirect ? "?noRedirect=1" : "");
    });
  };

  const handleColorLinkClick = (type) => {
    const el = document.querySelector(`.index-v2-${type}`);

    if (el) {
      if (document.scrollingElement.scrollTop < screen.availHeight) {
        scrollToY(screen.availHeight + 20);

        setTimeout(() => {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 300);
      } else {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const connect = async (type: EnumWallet) => {
    console.log("connect", { type });
    setConnectWay(type);
    connectWallet(type);
  };

  const getBalance = async () => {
    if (!info && timer) {
      clearInterval(timer);
      return;
    }

    if (!provider) {
      return;
    }

    try {
      const bc = await provider.getBalance(account);
      // console.log("bc", bc, utils.formatEther(bc));
      setweb3Balance(formatThousand(utils.formatEther(bc)));
    } catch (error) {
      console.error("getBalance error", { error });
    }
  };

  const getnonce = async (account) => {
    let { data, err } = await loginNonceApi({
      address: account,
      chain: USE_CHAIN_ID == 1 ? CHAINREQUEST[2] : CHAINREQUEST[1],
    });
    if (err) {
      return null;
    }
    return data;
  };

  const sign = async (nonce: string) => {
    const signer = provider.getSigner();
    let signature = await signer.signMessage(nonce);
    console.log("signature", signature);
    if (!signature) {
      return;
    }
    let { data, err } = await loginApi({
      address: account,
      signature,
      chain: USE_CHAIN_ID == 1 ? CHAINREQUEST[2] : CHAINREQUEST[1],
    });
    if (err) {
      return null;
    }
    return data;
  };

  const loginLoading = async () => {
    if (isActive && account) {
      const getnonceRes = await getnonce(account);
      console.log("getnonceRes", getnonceRes?.nonce);

      if (getnonceRes) {
        const signRes = await sign(getnonceRes.nonce);
        if (!signRes) {
          setConnectWay(EnumWallet.None);
          return;
        }
        const loginfo = {
          connectWay,
          account,
          token: signRes.jwtToken,
        };
        Cookies.set("logininfo", JSON.stringify(loginfo));
        setinfo(loginfo);
        setwvisabvle(false);
        setConnectWay(EnumWallet.None);

        if (router.query.redirect) {
          location.replace(decodeURIComponent(router.query.redirect as string));
        } else {
          router.reload();
        }
      } else {
        if (account) {
          const loginfo = {
            connectWay,
            account,
          };
          Cookies.set("logininfo", JSON.stringify(loginfo));
          setinfo(loginfo);
          setwvisabvle(false);
        }
        enqueueSnackbar("Please check network, get nonce error!", {
          variant: "error",
        });
        setConnectWay(EnumWallet.None);
      }
    }
  };

  const handleLogoClick = () => {
    if (router.pathname === "/") {
      scrollToY(0);
    } else {
      router.push("/");
    }
  };

  let navs;
  let showBack;
  let breadcrumbs;
  if (router.pathname === "/") {
    navs = (
      <div className="text-white flex items-center ml-[32px]">
        <div
          className={classNames(
            "h-[16px] w-px bg-white animate-easein direction-reverse",
            {
              paused: ms < 3700,
            }
          )}
        ></div>

        <Link href="/cheersup" target="_blank">
          <ColorLink
            type="cup"
            className={classNames(
              "h-[24px] ml-[32px] animate-easein direction-reverse",
              {
                paused: ms < 3800,
              }
            )}
          />
        </Link>

        <ColorLink
          type="emoji"
          className={classNames(
            "h-[24px] ml-[32px] animate-easein direction-reverse",
            {
              paused: ms < 3900,
            }
          )}
          onClick={() => {
            handleColorLinkClick("emoji");
          }}
        />

        <Link
          href={LAUNCH_TOWER_URL}
          target="_blank"
          className={classNames(
            "relative h-[24px] ml-[32px] animate-easein direction-reverse",
            {
              paused: ms < 4000,
            }
          )}
        >
          <ColorLink type="launch-tower" className="h-[24px]" />
          <div className="absolute right-[-24px] top-[-12px] h-[16px] px-[8px] bg-[red] rounded-full flex items-center justify-center text-[12px] text-white">
            New
          </div>
        </Link>

        <Link
          href="/mall"
          target="_blank"
          className={classNames(
            "relative ml-[32px] animate-easein direction-reverse",
            {
              paused: ms < 4100,
            }
          )}
        >
          <ColorLink type="mall" className="h-[24px] mt-[-1px]" />
          {/* <div className="absolute left-[25px] top-[-12px] h-[16px] px-[8px] bg-[red] rounded-full flex items-center justify-center text-[12px] text-white">
            New
          </div> */}
        </Link>

        <Link
          href="/meme/land"
          target="_blank"
          className={classNames(
            "relative ml-[32px] animate-easein direction-reverse",
            {
              paused: ms < 4200,
            }
          )}
        >
          <ColorLink type="meme" className="h-[24px] mt-[-1px]" />
        </Link>
      </div>
    );
  } else if (router.pathname.includes("/cheersup")) {
    navs = router.pathname === "/cheersup" && (
      <div>
        <div className="text-white flex items-center ml-[32px]">
          <div
            className={classNames(
              "h-[16px] w-px bg-white animate-easein direction-reverse",
              {
                paused: ms < 3700,
              }
            )}
          ></div>
          <Link href="/cheersup/about" target="_blank">
            <ColorLink
              type="pass-ticket"
              className={classNames(
                "h-[24px] ml-[32px] animate-easein direction-reverse",
                {
                  paused: ms < 3800,
                }
              )}
            />
          </Link>

          <div
            className={classNames(
              "btn-unstyled relative ml-[32px] animate-easein direction-reverse",
              {
                paused: ms < 4000,
              }
            )}
            onClick={() => {
              if (!isLoggedIn) {
                const el: HTMLButtonElement = document.querySelector(
                  ".nav-btn-connect-wallet"
                );
                if (el) {
                  el.click();
                }
                return;
              }

              window.open(`/${locale}/points`);
            }}
          >
            <ColorLink type="cup-points" className="h-[24px] mt-[-1px]" />
          </div>
        </div>
      </div>
    );

    const paths = router.pathname.slice(1).split("/");
    showBack = history?.length >= 2 && paths.length > 1;

    if (paths.length > 1) {
      if (paths[1] === "about") {
        showBack = false;
      }

      const pathMap = {
        roadmap: "ROADMAP",
        about: "ABOUT",
      };

      breadcrumbs = (
        <div className="flex items-center ml-[16px]">
          <div className="w-[40px] h-px bg-white mr-[16px]"></div>
          <div className=" font-bold text-[16px]">{pathMap[paths[1]]}</div>
        </div>
      );
    }
  }

  useEffect(() => {
    const info = getLoginInfo();
    if (info) {
      setinfo(info);
      if (!isActive) {
        connectWallet(info.connectWay, true);
      }
    }
  }, []);

  useEffect(() => {
    console.log("[info, provider]", { info, provider });
    if (provider) {
      if (!timer) {
        let timer = setInterval(() => {
          getBalance();
        }, 3000);
        settimer(timer);
      }
      initialized.current = true;
    }
  }, [provider]);

  useEffect(() => {
    if (isActive && connectWay) {
      loginLoading();
    }
  }, [isActive, connectWay]);

  useEffect(() => {
    console.log("hook account change", account);
    if (account && info && info.account != account) {
      logout();
    }
  }, [account]);

  useEffect(() => {
    console.log("hook chainId change", chainId);
    if (chainId && chainId != USE_CHAIN_ID) {
      enqueueSnackbar(
        `Please switch the ${
          USE_CHAIN_ID == CHAIN_IDS.Mainnet ? "Mainnet" : "Goerli"
        } to continue!`,
        {
          variant: "error",
        }
      );
      setTimeout(() => {
        logout();
      }, 2000);
    }
  }, [chainId]);

  useEffect(() => {
    // console.log("hook isActive change", {
    //   account,
    //   isActive,
    //   provider,
    //   initialized,
    // });
    if (initialized.current && !isActive) {
      enqueueSnackbar("Wallet disconnect", {
        variant: "error",
      });
      setTimeout(() => {
        logout();
      }, 2000);
    }
  }, [isActive]);

  useEffect(() => {
    if (web3Error) {
      console.error("web3Error", { web3Error });
      setConnectWay(EnumWallet.None);

      //未装metamask跳转matemask官网
      if (web3Error.name === "NoMetaMaskError") {
        enqueueSnackbar("Please install MetaMask to continue!", {
          variant: "error",
        });
        window.open("https://metamask.io/download/");
      } else {
        enqueueSnackbar(web3Error.message, {
          variant: "error",
        });
      }
    }
  }, [web3Error]);

  useEffect(() => {
    console.log("profileData", profileData);
    if (profileData?.err?.response?.data?.code === 2) {
      setinfo(null);
      Cookies.remove("logininfo");
    }
  }, [profileData]);

  useEffect(() => {
    setMenuMaskOpen(false);
  }, [router.pathname]);

  return (
    <>
      {/* 导航菜单 */}
      <div
        className={`fixed w-full left-0 top-0 z-[100] h-[100px] min-w-[1100px] text-white transition`}
        style={{ background: menuMaskOpen ? "black" : navBgc }}
        onClick={() => {
          if (menuMaskOpen) {
            setMenuMaskOpen(false);
          }
        }}
      >
        <nav
          className={classNames(
            "h-[100%] flex w-[100%] justify-between pr-[60px] items-center",
            { "pl-[60px]": !showBack }
          )}
        >
          <div className="flex items-center">
            {showBack && (
              <Button
                variant="contained"
                color="info"
                className={
                  "w-[100px] px-[24px] justify-end rounded-l-none h-[48px] mr-[16px]"
                }
                onClick={() => {
                  router.back();
                }}
              >
                <Image src="/icon/nav-back.svg" width={19} height={14} alt="" />
              </Button>
            )}

            <div
              className={classNames(
                "h-[46px] rounded-[10px] flex items-center",
                {
                  "animate-easein direction-reverse": enableAnimate,
                  paused: ms < 3600,
                }
              )}
            >
              {["/licence-guide"].includes(router.pathname) ||
              router.pathname.includes("/cheersup") ? (
                <Link href="/cheersup" className="h-[36px]">
                  <Image
                    src="/icon/cheers-up-logo.svg"
                    className="align-top"
                    alt=""
                    width={142}
                    height={36}
                  />
                </Link>
              ) : (
                <button
                  className="btn-unstyled h-[28px]"
                  onClick={handleLogoClick}
                >
                  <img src="/img/logo-28h.png" height={28} draggable={false} />
                </button>
              )}
            </div>

            {navs}
            {breadcrumbs}

            {router.pathname === "/mall" && (
              <div className="flex items-center ml-[16px]">
                <div className="w-[40px] h-px bg-white mr-[16px]"></div>
                <div className=" font-bold text-[16px]">MALL</div>
              </div>
            )}
          </div>

          {router.pathname !== "/denied" && (
            <div
              className={classNames("flex items-center", {
                "animate-easein direction-reverse": enableAnimate,
                paused: ms < 4000,
              })}
            >
              {!account || !profile ? (
                <div className={classNames("flex items-center")}>
                  <LocaleSwitcherV2 className="mr-[8px]" />
                  <Button
                    onClick={() => setwvisabvle(true)}
                    variant="contained"
                    color="info"
                    className={
                      "nav-btn-connect-wallet first-letter:h-[48px] px-[24px] rounded-[28px] h-[48px]"
                    }
                  >
                    <span className="text-[16px] font-medium">{t("6")}</span>
                  </Button>
                </div>
              ) : (
                <div className={classNames("flex items-center")}>
                  {menuMaskOpen ? (
                    <div className="mr-[16px] font-bold">{t("7")}</div>
                  ) : (
                    <>
                      {!router.pathname.includes("/cheersup") && (
                        <Link passHref href="/collection">
                          <BorderButton
                            size="m"
                            className="px-[24px]"
                            icon={
                              <div className="w-[20px] h-[20px]">
                                <Image
                                  width={20}
                                  height={20}
                                  src={`${location.protocol}${profile.avatarUrl}`}
                                  className={"rounded-full bg-[#E3E5E7]"}
                                  priority
                                />
                              </div>
                            }
                          >
                            {t("10")}
                          </BorderButton>
                        </Link>
                      )}

                      <LocaleSwitcherV2 className="mx-[8px]" />
                    </>
                  )}
                  <NavbarMenuIcon
                    close={menuMaskOpen}
                    onClick={(event) => {
                      event.stopPropagation();
                      setMenuMaskOpen(!menuMaskOpen);
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {(((enableAnimate || showWhiteLine) && navBgc === "black") ||
            menuMaskOpen) && (
            <div
              className={classNames(
                "absolute bottom-0 left-0 h-[2px] bg-white w-full",
                {
                  "animate-growWidth": enableAnimate,
                  paused: ms < 3000,
                }
              )}
            ></div>
          )}
        </nav>
      </div>
      {wvisabvle && (
        <Wallet
          open={wvisabvle}
          loading={!!connectWay}
          handleClose={() => {
            setConnectWay(EnumWallet.None);
            setwvisabvle(false);
          }}
          handelConnect={(type) => {
            connect(type);
          }}
        />
      )}
      {menuMaskOpen && profile && (
        <MenuMask
          profile={profile}
          balance={web3balance}
          account={account}
          onLogOutClick={() => {
            logout(true);
            setMenuMaskOpen(false);
          }}
        />
      )}
    </>
  );
}
