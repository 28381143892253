import classnames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { ColorLink } from "./index-v2/color-link";

export const LocaleSwitcherV2 = ({ className = "", ...restProps }) => {
  const router = useRouter();
  const { locale } = router;

  return (
    (<Link
      passHref
      href={{ query: router.query }}
      locale={locale === "en" ? "cn" : "en"}
    >

      <ColorLink
        className={classnames(
          "w-[48px] h-[48px] rounded-full border border-solid border-white mx-[16px] font-medium transition hover:bg-white hover:text-black",
          locale === "en" ? "btn-en" : "btn-cn"
        )}
        plainText={locale.toUpperCase()}
        colorText={locale === "en" ? "CN" : "EN"}
        {...restProps}
      />

    </Link>)
  );
};
