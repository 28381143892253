import type { AddEthereumChainParameter } from "@web3-react/types";

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter["nativeCurrency"];
  blockExplorerUrls: AddEthereumChainParameter["blockExplorerUrls"];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export const CHAINS: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
} = {
  1: {
    urls: [
      "https://mainnet.infura.io/v3/192aa5fd4a7c4ceb97445cf5cba43e42",
      "https://mainnet.infura.io/v3/102aa35ec48847f2a8d7b2ce8535c0fe",
      "https://mainnet.infura.io/v3/c6cf874733de42fe97f1766789584c89",
      "https://mainnet.infura.io/v3/2ccd842f094949b0a683cee26bb3eed1",
      "https://mainnet.infura.io/v3/5f75c89d36574f5bb82dbbc5c3dd0f76",
      "https://mainnet.infura.io/v3/5f9b344fc1324c98be129122d57590e6",
      "https://mainnet.infura.io/v3/eb20ca37aa984fb8b8427a1b56837672",
    ],
    name: "Mainnet",
  },
  5: {
    urls: ["https://goerli.infura.io/v3/1e90ba38b84149bd9b885e5971813f00"],
    name: "Goerli",
  },
};

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});
