import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useOverview } from "./useOverview";

let timerId;

let listeners = [];

function startTimer(listener) {
  listeners.push(listener);
  if (!timerId) {
    timerId = setInterval(() => {
      // console.log("interval", timerId);
      listeners.forEach((listener) => listener());
    }, 1000);
  }
}

function removeListener(listener) {
  listeners = listeners.filter((l) => l != listener);
  if (listeners.length === 0) {
    clearInterval(timerId);
    timerId = null;
  }
}

export function useNow() {
  const { overview } = useOverview();
  const [now, setNow] = useState<Dayjs>(dayjs());
  const listener = useCallback(() => {
    let _now = dayjs();
    if (overview?.timeDiff) {
      _now = _now.subtract(overview.timeDiff, "ms");
    }
    // console.log("_now", {
    //   overview,
    //   // @ts-ignore
    //   beijingTime: _now.utcOffset(8).format(),
    // });
    setNow(_now);
  }, [overview]);

  const stopNow = () => {
    console.log("stopNow");
    removeListener(listener);
  };

  // console.log("stopNow", { stopNow });

  useEffect(() => {
    startTimer(listener);

    return () => {
      stopNow();
    };
  }, [overview]);

  return { now, stopNow };
}
