import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import { themeOptions } from "./theme";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    thrid: true;
  }
}

const theme = createTheme({
  typography: {
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            textTransform: "none",
            background: `${themeOptions.palette.primary.main}`,
            border: "none",
            color: "#FFF",
            boxShadow: "none",
            borderRadius: "28px",
            "&:hover": {
              background: `${themeOptions.palette.primary.hover}`,
              border: "none",
              color: "#FFF",
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            textTransform: "none",
            background: `${themeOptions.palette.secondary.main}`,
            border: "none",
            color: "#FFF",
            boxShadow: "none",
            borderRadius: "28px",
            "&:hover": {
              background: `${themeOptions.palette.secondary.main}`,
              color: "#FFF",
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "contained", color: "info" },
          style: {
            textTransform: "none",
            background: `${themeOptions.palette.info.main}`,
            border: `1px solid ${themeOptions.palette.info.main}`,
            color: "#000",
            boxShadow: "none",
            borderRadius: "28px",
            "&:hover": {
              background: `${themeOptions.palette.info.hover}`,
              border: `1px solid ${themeOptions.palette.info.hover}`,
              boxShadow: "none",
            },
            "&:disabled": {
              background: `${themeOptions.palette.info.main}`,
              border: `1px solid ${themeOptions.palette.info.main}`,
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            border: "1px solid #FFF",
            boxShadow: "none",
            borderRadius: "28px",
            color: "#FFF",
            "&:hover": {
              border: `1px solid ${themeOptions.palette.hoverColor.main}`,
              color: `${themeOptions.palette.hoverColor.main}`,
              boxShadow: "none",
              background: "transparent",
            },
            "&:disabled": {
              border: "1px solid #FFF",
              color: "#FFF",
            },
          },
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            border: "1px solid #000",
            boxShadow: "none",
            borderRadius: "28px",
            color: `${themeOptions.palette.secondary.main}`,
            "&:hover": {
              border: `1px solid ${themeOptions.palette.hoverColor.main}`,
              color: `${themeOptions.palette.hoverColor.main}`,
              boxShadow: "none",
              background: "transparent",
            },
            "&:disabled": {
              border: `1px solid ${themeOptions.palette.hoverColor.main}`,
              color: `${themeOptions.palette.secondary.main}`,
            },
          },
        },
      ],
    },
  },
});

export default function CustomizedButtons({
  startIcon,
  variant,
  children,
  className = "",
  disabled = false,
  onClick,
  component,
  color = "primary",
  loading = false,
}: {
  startIcon?: React.ReactNode;
  variant: "contained" | "outlined";
  children: JSX.Element | JSX.Element[];
  className: string;
  disabled?: boolean;
  onClick?: () => void;
  component?: any;
  color?: "primary" | "secondary" | "info";
  loading?: boolean;
}) {
  const params = {};
  if (startIcon) {
    params["loadingPosition"] = "start";
  }
  return (
    <ThemeProvider theme={theme}>
      <LoadingButton
        disableRipple={true}
        loadingIndicator={
          <CircularProgress
            style={{ marginLeft: 14 }}
            color="inherit"
            size={16}
          />
        }
        loading={loading}
        {...params}
        startIcon={startIcon}
        color={color}
        variant={variant}
        className={className}
        disabled={disabled}
        onClick={onClick}
        component={component}
      >
        {children}
      </LoadingButton>
    </ThemeProvider>
  );
}
