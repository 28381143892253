import { useEffect, useRef, useState } from "react";

export const useMs = ({ step = 100, maxMs = 500, autoStart = true }) => {
  const [ms, setMs] = useState(0);
  const timerRef = useRef<NodeJS.Timeout>();

  const msRef = useRef<number>();
  msRef.current = ms;

  const startMs = () => {
    setMs(0);
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      // console.log("maxMs", maxMs);
      if (msRef.current > maxMs) {
        clearInterval(timerRef.current);
      }
      setMs((ms) => ms + step);
    }, step);
  };

  const resetMs = () => {
    clearInterval(timerRef.current);
    setMs(0);
  };

  useEffect(() => {
    if (autoStart) {
      startMs();
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  return { ms, startMs, resetMs };
};
