import { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";

import "intersection-observer";
import { GTM_ID, pageview } from "../lib/gtm";
import WalletProvider from "../components/WalletProvider";
import "../styles/globals.scss";
import Layout from "../components/Layout";
import MessageTip from "../components/UI/MessageTip";
import { RewardsProvider, useOverview } from "../hooks";
import { DeniedError } from "../utils/api";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  console.log("MyApp router", router);

  const { overview, fetchOverview } = useOverview();
  // console.log("MyApp overview", overview);

  useEffect(() => {
    // Prevent automatic page location restoration
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
    fetchOverview()
      .then(() => {
        if (router.pathname === "/denied") {
          // router.replace("/");
        }
      })
      .catch((err) => {
        if (err instanceof DeniedError && router.pathname !== "/denied") {
          router.replace("/denied");
        }
      });
    const handleRouteChange = (url) => {
      pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>CryptoNatty</title>
        <meta name="viewport" content="width=device-width,viewport-fit=cover" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
      </Head>
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtm-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
      <Script src="/js/nft-viewer.min.js" />
      <MessageTip>
        <WalletProvider>
          <RewardsProvider>
            {["/about", "/brew-rules", "/stake-rules"].includes(
              router.pathname
            ) ? (
              <Component key={router.pathname} {...pageProps} />
            ) : (
              <Layout>
                <Component key={router.pathname} {...pageProps} />
              </Layout>
            )}
          </RewardsProvider>
        </WalletProvider>
      </MessageTip>
    </>
  );
}

export default appWithTranslation(MyApp);
