import classNames from "classnames";
import { FC, ReactNode } from "react";
import { Loading } from "./loading";

interface IProps {
  className?: string;
  size?: "xs" | "s" | "m" | "l";
  disabled?: boolean;
  children: ReactNode;
  icon?: ReactNode;
  loading?: boolean;
  justify?: "start" | "center" | "end";
  onClick?(event): void;
}

const getSize = (size) => {
  let cls;

  switch (size) {
    case "s":
      cls = "h-[40px] text-[16px] px-[20px] border";
      break;

    case "m":
      cls = "h-[48px] text-[16px] px-[24px] border";
      break;

    case "l":
      cls = "h-[56px] text-[18px]  px-[28px] border-2";
      break;

    default:
      break;
  }

  return cls;
};

export const BorderButton: FC<IProps> = ({
  children,
  className,
  size = "s",
  disabled = false,
  icon = null,
  loading = false,
  justify = "center",
  ...rest
}) => {
  return (
    <button
      className={classNames(
        "btn-unstyled font-medium rounded-full text-white border-solid border-white flex items-center transition",
        getSize(size),
        {
          "justify-start": justify === "start",
          "justify-center": justify === "center",
          "justify-end": justify === "end",
        },
        { "hover:bg-white hover:text-black": !loading && !disabled },
        { "opacity-80 cursor-not-allowed": loading || disabled },
        className
      )}
      disabled={disabled || loading}
      {...rest}
    >
      {(loading || icon) && (
        <div className="mr-[8px]">{loading ? <Loading /> : icon}</div>
      )}
      {children}
    </button>
  );
};
