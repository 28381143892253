import {
  CHEER_UP_EMOJI_ADDRESS,
  CHEER_UP_ADDRESS,
  CHEER_UP_BLIND_BOX_ADDRESS,
  CHEER_UP_BADGE_ADDRESS,
  FPX_EMOJI_ADDRESS,
} from "../constants";

export const TabKeys = {
  all: "all",
  brewing: "brewing",
  staking: "staking",
  emoji: "emoji",
};

export const PageTabs = [
  {
    name: "All",
    tabKey: TabKeys.all,
  },
  {
    name: "Brewing",
    tabKey: TabKeys.brewing,
  },
  {
    name: "Staking",
    tabKey: TabKeys.staking,
  },
  {
    name: "NFT Emoji",
    tabKey: TabKeys.emoji,
  },
];

export const CollectionTypes = {
  cheersUpPeriod: "1",
  cheersUp: "2",
  cheersUpBadge: "3",
  cheersUpEmoji: "4",
  fpxEmoji: "5",
};

export const CollectionAddress = {
  [CollectionTypes.cheersUpPeriod]: CHEER_UP_BLIND_BOX_ADDRESS,
  [CollectionTypes.cheersUp]: CHEER_UP_ADDRESS,
  [CollectionTypes.cheersUpBadge]: CHEER_UP_BADGE_ADDRESS,
  [CollectionTypes.cheersUpEmoji]: CHEER_UP_EMOJI_ADDRESS,
  [CollectionTypes.fpxEmoji]: FPX_EMOJI_ADDRESS,
};

export const BrewStatus = {
  init: "init",
  loading: "loading",
  completed: "completed",
};

export const MAX_BREW_COUNT = 50;

export const MAX_STAKE_COUNT = 30;

export enum RewardSource {
  REWARD_SOURCE_BREW = "REWARD_SOURCE_BREW",
  REWARD_SOURCE_STAKE = "REWARD_SOURCE_STAKE",
}

export enum RewardType {
  UNKNOWN_REWARD = "UNKNOWN_REWARD",
  VIP_MONTH_CARD_REWARD = "VIP_MONTH_CARD_REWARD", // 月卡类型
  VOUCHER_REWARD = "VOUCHER_REWARD", // 待领取的兑换券类型
  AIRDROP_EMOJI_REWARD = "AIRDROP_EMOJI_REWARD", // EMOJI
  LUCKY_DRAW_REWARD = "LUCKY_DRAW_REWARD", // 抽奖
}

export enum RewardSubType {
  UNKNOWN_SUB_REWARD = "UNKNOWN_SUB_REWARD",

  VIP_CARD_SUB_REWARD = "VIP_CARD_SUB_REWARD",

  PRESENT_BOX_SUB_REWARD = "PRESENT_BOX_SUB_REWARD",
  RAFFLE_TICKET_SUB_REWARD = "RAFFLE_TICKET_SUB_REWARD",

  Airdrop_EMOJI_SUB_REWARD = "Airdrop_EMOJI_SUB_REWARD",

  HEAD_OF_CUP_SUB_REWARD = "HEAD_OF_CUP_SUB_REWARD",
  LUCKY_DRAW_CUP_PERIOD_SUB_REWARD = "LUCKY_DRAW_CUP_PERIOD_SUB_REWARD",
}

export enum ClaimStatus {
  WAIT_CLAIM = "WAIT_CLAIM", // 待领取
  CLAIMED = "CLAIMED", // 已领取
  Expired = "Expired", // 过期
}

export enum ClaimSubStatus {
  UNKNOWN_SUB_STATUS = "UNKNOWN_SUB_STATUS", // 默认状态，此状态下按照原来逻辑
  DELIVERED_SUB_STATUS = "DELIVERED_SUB_STATUS", // 已发货状态，该状态下不允许进行编辑更新了
  ADDRERROR_SUB_STATUS = "ADDRERROR_SUB_STATUS", // 地址异常，该状态下允许调用修改地址接口
  REEDIT_SUB_STATUS = "REEDIT_SUB_STATUS", // 地址异常下用户编辑一次后进入到该状态，在deadline之前可以反复编辑地址
  NotWin_SUB_STATUS = "NotWin_SUB_STATUS", // 未中奖
}

export const RewardsTabKeys = {
  VipCard: "VipCard",
  Emoji: "Emoji",
  Physical: "Physical",
  LuckDraw: "LuckDraw",
};
