import { useCallback, useEffect, useState } from "react";
import { overviewApi, DeniedError } from "../utils/api";

let loading = false;
let cacheOverview;
let listeners = [];

function observeCacheOverview(cb) {
  console.log("observeCacheOverview");
  listeners.push(cb);
}

function unobserveCacheOverview(cb) {
  console.log("observeCacheOverview");
  listeners = listeners.filter((listeners) => listeners != cb);
}

function setCacheOverview(data) {
  console.log("setCacheOverview", data);
  cacheOverview = data;
  listeners.forEach((cb) => cb(data));
}

export function useOverview() {
  // console.log('cacheOverview', { cacheOverview });
  const [overview, setOverview] = useState(cacheOverview);

  const fetchOverview = async () => {
    if (!loading) {
      try {
        loading = true;
        const res = (await overviewApi()) as any;
        cacheOverview = {
          ...res.data,
          timeDiff: Date.now() - res.data.ts * 1000, // 比服务器时间快 x ms
        };
        setCacheOverview(cacheOverview);
        setOverview(cacheOverview);
      } catch (error) {
        console.error("fetchOverview", { error });
        if (error instanceof DeniedError) {
          throw error;
        }
      } finally {
        loading = false;
      }
    }
  };

  const listenOverview = useCallback((_overview) => {
    setOverview(_overview);
  }, []);

  useEffect(() => {
    observeCacheOverview(listenOverview);

    return () => {
      unobserveCacheOverview(listenOverview);
    };
  }, []);

  return { overview, fetchOverview };
}
