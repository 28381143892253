import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useHistory = () => {
  const [history, setHistory] = useState<History>();
  const router = useRouter();

  useEffect(() => {
    setHistory(window.history);
  }, [router]);

  return history;
};
