import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useTrail, a } from "@react-spring/web";
import { BorderButton } from "../../components/UI/BorderButton";
import SvgIcon from "@mui/material/SvgIcon";
import Checkbox from "@mui/material/Checkbox";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { EnumWallet } from "../../constants";
import { useTranslation } from "next-i18next";
// import { useWeb3React } from "@web3-react/core";

function BackIcon(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0711 3.05021C15.6569 2.46442 15.6569 1.51467 15.0711 0.928888C14.4853 0.343102 13.5355 0.343101 12.9497 0.928888L8 5.87863L3.05025 0.928887C2.46447 0.343101 1.51472 0.3431 0.928932 0.928887C0.343146 1.51467 0.343146 2.46442 0.928932 3.05021L5.87868 7.99996L0.928932 12.9497C0.343146 13.5355 0.343146 14.4852 0.928932 15.071C1.51472 15.6568 2.46447 15.6568 3.05025 15.071L8 10.1213L12.9497 15.071C13.5355 15.6568 14.4853 15.6568 15.0711 15.071C15.6569 14.4852 15.6569 13.5355 15.0711 12.9497L10.1213 7.99996L15.0711 3.05021Z"
      />
    </SvgIcon>
  );
}

let timeout = null;

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(51, 51, 51, 0.8)",
    marginLeft: "-23px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(51, 51, 51, 0.8)",
    borderRadius: "6px",
    padding: "10px 12px",
    fontSize: "12px",
    lineHeight: "100%",
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: "5px !important",
    marginLeft: "30px",
  },
}));

export default function WallectCom({
  open = false,
  handleClose,
  handelConnect,
  loading,
}: {
  open: boolean;
  handleClose?: () => void;
  handelConnect?: (enum1: EnumWallet) => void;
  loading: boolean;
}) {
  const { t } = useTranslation();
  // const { connector, provider, account } = useWeb3React();
  const trail = useTrail(1, {
    config: { mass: 1, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    y: 0,
    height: open ? 110 : 0,
    from: { opacity: 0, x: 20, y: 0, height: 0 },
  });

  const [open2, setOpen] = React.useState(false);
  const [checked, setchecked] = React.useState(false);

  const connect = (type: EnumWallet) => {
    if (!checked) {
      handleTooltipOpen();
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        handleTooltipClose();
      }, 3000);
    } else {
      handelConnect(type);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose ? handleClose : () => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div>
        {trail.map(({ height, ...style }, index) => (
          <a.div key={index} style={style}>
            <a.div style={{ height }}>
              <Box
                className={
                  "flex flex-col justify-start items-center absolute top-0 right-0 w-[496px] px-[80px] pb-[60px] bg-[#18191C] text-white"
                }
                style={{ borderBottomLeftRadius: 30 }}
              >
                <div
                  className={
                    "flex justify-between items-center w-[336px] mt-[47px] mb-[40px]"
                  }
                >
                  <span className="block text-[16px]">{t("1")}</span>
                  <BackIcon
                    onClick={() => handleClose()}
                    className="block text-white w-[30px] h-[30px] p-[7px] cursor-pointer c_roate"
                  />
                </div>
                <BorderButton
                  onClick={() => connect(EnumWallet.Metamask)}
                  icon={
                    <img
                      className="w-[32px] h-[32px]"
                      src="/icon/metamask.png"
                    />
                  }
                  size="l"
                  className="w-full pl-[32px]"
                  justify="start"
                  loading={loading}
                >
                  {"Metamask".toUpperCase()}
                </BorderButton>
                <BorderButton
                  onClick={() => connect(EnumWallet.WalletConnect)}
                  icon={
                    <img
                      className="w-[32px] h-[32px]"
                      src="/icon/walletconnect.png"
                    />
                  }
                  size="l"
                  className="w-full mt-[16px] pl-[32px]"
                  justify="start"
                  loading={loading}
                >
                  {"Wallet Connect".toUpperCase()}
                </BorderButton>
                <div
                  className={
                    "flex items-start mt-[40px] text-[14px] tracking-[.06em]"
                  }
                >
                  <BootstrapTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={open2}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Please check if you agree"
                  >
                    <Checkbox
                      className="px-0 py-1 pr-[16px]"
                      onChange={(e) => {
                        setchecked(e.currentTarget.checked);
                        if (e.currentTarget.checked) {
                          handleTooltipClose();
                        }
                      }}
                      checked={checked}
                      color="default"
                      icon={
                        <SvgIcon
                          sx={{ width: 16, height: 16 }}
                          viewBox="0 0 16 16"
                        >
                          <rect width="16" height="16" rx="4" fill="#343434" />
                        </SvgIcon>
                      }
                      checkedIcon={
                        <SvgIcon
                          sx={{ width: 16, height: 16 }}
                          viewBox="0 0 16 16"
                        >
                          <rect width="16" height="16" rx="4" fill="white" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.2249 4.67106C12.0326 4.49784 11.7362 4.51337 11.5629 4.70576L6.31035 10.5394L4.44406 8.46663C4.27083 8.27424 3.97444 8.25871 3.78205 8.43193C3.58966 8.60516 3.57413 8.90155 3.74736 9.09394L5.95557 11.5464C6.08001 11.6846 6.268 11.7315 6.43534 11.6828C6.52259 11.6609 6.6045 11.6137 6.66921 11.5419L12.2596 5.33307C12.4329 5.14068 12.4173 4.84429 12.2249 4.67106Z"
                            fill="black"
                          />
                          <path
                            d="M11.5629 4.70576L11.1914 4.37119L11.1914 4.37119L11.5629 4.70576ZM12.2249 4.67106L12.5595 4.29949L12.5595 4.29949L12.2249 4.67106ZM6.31035 10.5394L5.93877 10.8739L6.31035 11.2866L6.68192 10.8739L6.31035 10.5394ZM4.44406 8.46663L4.07248 8.80119H4.07248L4.44406 8.46663ZM3.78205 8.43193L3.44749 8.06036H3.44749L3.78205 8.43193ZM3.74736 9.09394L3.37579 9.4285H3.37579L3.74736 9.09394ZM5.95557 11.5464L6.32714 11.2118H6.32714L5.95557 11.5464ZM6.43534 11.6828L6.31369 11.1978L6.30457 11.2001L6.29554 11.2028L6.43534 11.6828ZM6.66921 11.5419L6.29764 11.2073H6.29764L6.66921 11.5419ZM12.2596 5.33307L11.8881 4.9985L11.8881 4.9985L12.2596 5.33307ZM11.9345 5.04032C11.923 5.05315 11.9032 5.05418 11.8904 5.04264L12.5595 4.29949C12.1619 3.94149 11.5494 3.97359 11.1914 4.37119L11.9345 5.04032ZM6.68192 10.8739L11.9345 5.04032L11.1914 4.37119L5.93877 10.2048L6.68192 10.8739ZM6.68192 10.2048L4.81563 8.13206L4.07248 8.80119L5.93877 10.8739L6.68192 10.2048ZM4.81563 8.13206C4.45763 7.73446 3.84509 7.70236 3.44749 8.06036L4.11662 8.80351C4.10379 8.81505 4.08403 8.81402 4.07248 8.80119L4.81563 8.13206ZM3.44749 8.06036C3.04989 8.41836 3.01778 9.0309 3.37579 9.4285L4.11893 8.75937C4.13048 8.7722 4.12944 8.79196 4.11662 8.8035L3.44749 8.06036ZM3.37579 9.4285L5.584 11.881L6.32714 11.2118L4.11893 8.75937L3.37579 9.4285ZM5.584 11.881C5.84167 12.1671 6.23057 12.2632 6.57514 12.1629L6.29554 11.2028C6.30126 11.2011 6.30665 11.2012 6.31175 11.2026C6.31773 11.2042 6.3235 11.2078 6.32714 11.2118L5.584 11.881ZM6.29764 11.2073C6.29923 11.2055 6.30167 11.2034 6.30481 11.2015C6.30783 11.1997 6.31086 11.1985 6.31369 11.1978L6.55699 12.1678C6.73762 12.1225 6.90751 12.0244 7.04079 11.8764L6.29764 11.2073ZM11.8881 4.9985L6.29764 11.2073L7.04079 11.8764L12.6312 5.66763L11.8881 4.9985ZM11.8904 5.04264C11.8776 5.03109 11.8765 5.01133 11.8881 4.9985L12.6312 5.66763C12.9892 5.27003 12.9571 4.65749 12.5595 4.29949L11.8904 5.04264Z"
                            fill="black"
                          />
                        </SvgIcon>
                      }
                    />
                  </BootstrapTooltip>
                  <div className={"text-white/60 leading-[24px] font-normal"}>
                    {t("2")}{" "}
                    <a
                      href="/pdf/pp.pdf"
                      target="_blank"
                      className="cursor-pointer inline-block a-hover-line text-white"
                    >
                      {t("3")}
                    </a>{" "}
                    {t("4")}{" "}
                    <a
                      href="/pdf/tos.pdf"
                      target="_blank"
                      className="cursor-pointer inline-block a-hover-line text-white"
                    >
                      {t("5")}
                    </a>
                  </div>
                </div>
              </Box>
            </a.div>
          </a.div>
        ))}
      </div>
    </Modal>
  );
}
