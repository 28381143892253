import BilibiliIcon from "../public/icon/bilibili.svg";
import TwitterIcon from "../public/icon/twitter.svg";
import MailIcon from "../public/icon/mail.svg";
import DiscordIcon from "../public/icon/discord.svg";
import classNames from "classnames";
import { useMs } from "@/hooks/useMs";
import { useTranslation } from "next-i18next";
import { CHEERS_UP_DISCORD_URL } from "../constants";
import { useRouter } from "next/router";
import Link from "next/link";

export function FooterV2({
  footerFixed = false,
  enableAnimate = false,
  showWhiteLine = false,
}) {
  const router = useRouter();
  const { ms } = useMs({ maxMs: 5000 });
  const { t } = useTranslation("common");

  return (
    <footer
      className={classNames(
        "min-w-[1100px] px-[60px] h-[100px] bg-black text-white -mt-[2px] flex items-center justify-between w-full",
        footerFixed ? "fixed bottom-0 z-[1]" : "relative"
      )}
    >
      {showWhiteLine && (
        <div
          className={classNames(
            "absolute top-0 right-0 h-[2px] bg-white w-full",
            { "animate-growWidth": enableAnimate, paused: ms < 3000 }
          )}
        ></div>
      )}
      <div
        className={classNames({
          "animate-easein direction-reverse": enableAnimate,
          paused: ms < 3800,
        })}
      >
        <a
          href="https://space.bilibili.com/2134690434?spm_id_from=333.337.0.0"
          target="_blank"
          rel="noreferrer"
        >
          <BilibiliIcon className="transition-all duration-300 rounded-full hover:bg-white hover:text-black" />
        </a>
        <a href={CHEERS_UP_DISCORD_URL} target="_blank" rel="noreferrer">
          <DiscordIcon className="transition-all duration-300 rounded-full hover:bg-white hover:text-black" />
        </a>
        <a
          href="https://twitter.com/CryptoNatty_io"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon className="transition-all duration-300 rounded-full hover:bg-white hover:text-black" />
        </a>
        <a
          href="mailto:baselabs@cryptonatty.io"
          target="_blank"
          rel="noreferrer"
        >
          <MailIcon className="transition-all duration-300 rounded-full hover:bg-white hover:text-black" />
        </a>
      </div>

      <div
        className={classNames("text-[12px]", {
          "animate-easein direction-reverse": enableAnimate,
          paused: ms < 3600,
        })}
      >
        {router.pathname.includes("/cheersup") && (
          <>
            <Link
              href="/"
              className="inline-block opacity-50 hover:opacity-100 a-hover-line border-solid border-0 border-b border-[rgba(255,255,255,0.5)]"
              rel="noreferrer"
            >
              CryptoNatty
            </Link>

            <a
              href="/pdf/Cheers Up - Purchase and Licence Agreement.pdf"
              target="_blank"
              className="ml-[16px] inline-block opacity-50 hover:opacity-100 a-hover-line border-solid border-0 border-b border-[rgba(255,255,255,0.5)]"
              rel="noreferrer"
            >
              {t("cup_licence")}
            </a>
          </>
        )}

        <a
          href="/pdf/pp.pdf"
          target="_blank"
          className="ml-[16px] inline-block opacity-50 hover:opacity-100 a-hover-line border-solid border-0 border-b border-[rgba(255,255,255,0.5)]"
          rel="noreferrer"
        >
          {t("13")}
        </a>

        <a
          href="/pdf/tos.pdf"
          target="_blank"
          className="ml-[16px] inline-block opacity-50 hover:opacity-100 a-hover-line border-solid border-0 border-b border-[rgba(255,255,255,0.5)]"
          rel="noreferrer"
        >
          {t("14")}
        </a>
        <span className="ml-[16px] opacity-50">©2022 CRYPTONATTY, Inc</span>
      </div>
    </footer>
  );
}
