import { RewardsTabKeys } from "constants/collection";
import { useEffect, useState, createContext, useContext } from "react";
import * as api from "utils/api";

interface ICurrentRewardItem {
  collectionType;
  tokenId;
}
interface IContextValue {
  rewardList?;
  fetchRewardList?;
  currentRewardItem?: ICurrentRewardItem;
  setCurrentRewardItem?(ICurrentRewardItem): void;
  modalActiveTabKey?;
  setModalActiveTabKey?;
}

export const RewardsContext = createContext<IContextValue>({});

export const RewardsProvider = ({ children }) => {
  const { rewardList, fetchRewardList } = useRewardList();
  const [currentRewardItem, setCurrentRewardItem] = useState(null); // null 不展示，{} 全部，ICurrentRewardItem 单个
  const [modalActiveTabKey, setModalActiveTabKey] = useState(
    RewardsTabKeys.VipCard
  );

  return (
    <RewardsContext.Provider
      value={{
        rewardList,
        fetchRewardList,
        currentRewardItem,
        setCurrentRewardItem,
        modalActiveTabKey,
        setModalActiveTabKey,
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
};

export const useRewards = () => {
  const ctx = useContext(RewardsContext);
  // console.log("useRewards", ctx);
  return ctx;
};

const useRewardList = () => {
  const [rewardList, setRewardList] = useState([]);

  const fetchRewardList = async (params) => {
    try {
      const res = await api.rewardList(params);
      console.log("fetchRewardList", res);
      setRewardList(res?.data?.rewards || []);
    } catch (error) {
      console.error("fetchRewardList error", error);
    }
  };

  return {
    rewardList,
    fetchRewardList,
  };
};
