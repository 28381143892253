import { useEffect, useRef } from "react";

export const useExecContractFunction = () => {
  const timerIdRef = useRef<NodeJS.Timeout>();

  const exec = ({ contract, functionName, functionParams, wait = 60 }) => {
    console.log("execContractFunction", {
      contract,
      functionName,
      functionParams,
    });

    return new Promise(async (resolve, reject) => {
      try {
        const estimateGas = await contract.estimateGas[functionName](
          ...functionParams
        );
        const gasLimit = estimateGas.mul(6).div(5);
        console.log("gasLimit", { estimateGas, gasLimit });

        const tx = await contract[functionName](...functionParams, {
          gasLimit,
        });
        console.log(`${functionName} tx`, tx);

        // 延迟判断 receipt.status 是否为1，若是则成功
        timerIdRef.current = setTimeout(async () => {
          const receipt = await tx.wait();
          console.log(`${functionName} receipt`, receipt);
          if (receipt.status === 1) {
            resolve(tx);
          } else {
            reject();
          }
        }, wait * 1000);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerIdRef.current);
    };
  }, []);

  return { exec };
};
