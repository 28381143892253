import classnames from "classnames";
import { FC, useEffect, useState } from "react";

interface INavbarMenuIconProps {
  close?: boolean;
  [k: string]: any;
}

export const NavbarMenuIcon: FC<INavbarMenuIconProps> = ({
  close = false,
  ...rest
}) => {
  return (
    <button
      className="btn-unstyled w-[48px] h-[48px] rounded-full bg-white flex flex-col justify-center items-center relative transition hover:opacity-80"
      {...rest}
    >
      <div
        className={classnames(
          "w-[22px] h-[3px] rounded-[22px] bg-black origin-center transition duration-300",
          { "translate-y-[4.5px] rotate-45": close }
        )}
      ></div>
      <div
        className={classnames(
          "w-[22px] h-[3px] rounded-[22px] bg-black mt-[6px] origin-center transition duration-300",
          { "translate-y-[-4.5px] rotate-[-45deg]": close }
        )}
      ></div>
    </button>
  );
};
