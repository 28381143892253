import {
  EnumWallet,
  ETHERSCAN_ORIGIN,
  LOOKSRARE_ORIGIN,
  OPENSEA_ORIGIN,
} from "../constants";
import { utils } from "ethers";
import Cookies from "js-cookie";

export function copyTocontent(str, cb) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
  cb();
}

export const formatThousand = utils.commify;

export function getLoginInfo():
  | {
      connectWay: EnumWallet;
      account: string;
      token?: string;
    }
  | undefined {
  const logininfostr = Cookies.get("logininfo");
  if (logininfostr) {
    try {
      return JSON.parse(logininfostr);
    } catch (error) {
      console.error("getLoginInfo", { error });
    }
  }
}

export function getNftCollectionUrl(name) {
  return `${OPENSEA_ORIGIN}/collection/${name}`;
}

export function getNftUrl(contractAddress, tokenId) {
  return `${OPENSEA_ORIGIN}/assets/ethereum/${contractAddress}/${tokenId}`;
}

export function getLooksRareUrl(contractAddress) {
  return `${LOOKSRARE_ORIGIN}/collections/${contractAddress}`;
}

export function getEtherscanContractUrl(contractAddress) {
  return `${ETHERSCAN_ORIGIN}/address/${contractAddress}`;
}

export function getDHMS(seconds: string | number, withDay = false) {
  let s = +seconds;

  const sPerD = 24 * 60 * 60;
  const sPerH = 60 * 60;
  const sPerM = 60;

  let d;
  if (withDay) {
    d = Math.floor(s / sPerD);
    s -= d * sPerD;
  }

  const h = Math.floor(s / sPerH);
  s -= h * sPerH;

  const m = Math.floor(s / sPerM);
  s -= m * sPerM;

  return {
    d,
    h,
    m,
    s,
  };
}

export function scrollToY(y) {
  const step = (y - document.scrollingElement.scrollTop) / (300 / 16.7);
  const direction = document.scrollingElement.scrollTop < y ? "down" : "up";

  // console.log("scrollToY", { step, direction });

  const scroll = () => {
    const scrollTop = document.scrollingElement.scrollTop;
    // console.log("scrollToY scrollTop", scrollTop);
    if (scrollTop < y && direction === "down") {
      console.log("< y");
      document.scrollingElement.scrollTop = Math.min(scrollTop + step, y);
      requestAnimationFrame(scroll);
    } else if (scrollTop > y && direction === "up") {
      console.log("> y");
      document.scrollingElement.scrollTop = Math.max(scrollTop + step, y);
      requestAnimationFrame(scroll);
    }
  };

  scroll();
}

export function isMobile() {
  if (typeof window === 'undefined') return false
  return / Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /i.test(
    navigator.userAgent
  );
}
