/** 处理cheersup盲盒合约报错 */
export const cheersupBlindBoxErrorHandler = (
  errorString: string
): string[] | undefined => {
  console.log("cheersupBlindBoxErrorHandler", errorString);

  if (typeof errorString !== "string") {
    return;
  }

  const errors = [
    [
      "whitelist sale has not enabled",
      "Please wait a moment, minting has not started yet.",
    ],
    [
      "caller is not in whitelist or invalid signature",
      "Sorry, you aren't on the winner list.",
    ],
    [
      "max mint amount per wallet exceeded",
      "The mint amount has exceeded your allowed limit.",
    ],
    ["invalid number of tokens", "Oops, the amount of minting looks like 0."],
    [
      "can only mint MAX_TOKEN_PER_MINT tokens at a time",
      "The mint amount has exceeded your allowed limit.",
    ],
    ["max supply exceeded", "The mint amount has exceeded your allowed limit."],
    [
      "ether value sent is not correct",
      "Insufficient funds for mint price and gas.",
    ],
    ["insufficient funds", "Insufficient funds for mint price and gas."],
    ["UNPREDICTABLE_GAS_LIMIT", "Insufficient funds for mint price and gas."],
    [
      "public sale has not enabled",
      "Please wait a moment, minting has not started yet.",
    ],
    [
      "reveal has not enabled",
      "Revealing has not started yet. Please follow our latest news.",
    ],
    [
      "caller is not owner",
      "Looks like you don’t have any Cheers Period. You could buy one on OpenSea.",
    ],
    ["token transfer paused", "Cheers UP is in maintenance. Please hold on."],
  ];

  for (let error of errors) {
    if (errorString.includes(error[0])) {
      return error;
    }
  }
};
