export const CHEERS_UP_PERIOD_VIDEO_URL =
  "https://images.cryptonatty.io/videos/cheers-up-blind-box.mp4";

export const USE_CHAIN_ID = process.env.NEXT_PUBLIC_USE_CHAIN_ID
  ? parseInt(process.env.NEXT_PUBLIC_USE_CHAIN_ID)
  : 1;

export const CHAIN_IDS = {
  Mainnet: 1,
  Goerli: 5,
};

export const CHAINREQUEST = [
  "CHAIN_UNKNOWN",
  "CHAIN_ETHEREUM_GOERLI",
  "CHAIN_ETHEREUM_MAINNET",
  "CHAIN_FLOW_TESTNET",
  "CHAIN_FLOW_MAINNET",
];

export const GET_ALLOW_LIST_URL = process.env.NEXT_PUBLIC_GET_ALLOW_LIST_URL;
export const CHEERS_UP_ETH = String(process.env.NEXT_PUBLIC_CHEERS_UP_ETH);
export const CHEERS_UP_DISCORD_URL = "https://discord.gg/cheersup";

export const CHEERS_UP_OPENSEA_COLLECTION_NAME =
  process.env.NEXT_PUBLIC_CHEERS_UP_OPENSEA_COLLECTION_NAME;

export const CHEERS_UP_PERIOD_OPENSEA_COLLECTION_NAME =
  process.env.NEXT_PUBLIC_CHEERS_UP_PERIOD_OPENSEA_COLLECTION_NAME;

export const CHEERS_UP_EMOJI_OPENSEA_COLLECTION_NAME =
  process.env.NEXT_PUBLIC_CHEERS_UP_EMOJI_OPENSEA_COLLECTION_NAME;

export const CHEERS_UP_BADGE_OPENSEA_COLLECTION_NAME =
  process.env.NEXT_PUBLIC_CHEERS_UP_BADGE_OPENSEA_COLLECTION_NAME;

export const CHEER_UP_ADDRESS = process.env.NEXT_PUBLIC_CHEER_UP_ADDRESS;

export const CHEER_UP_BLIND_BOX_ADDRESS =
  process.env.NEXT_PUBLIC_CHEER_UP_BLIND_BOX_ADDRESS;

export const CHEER_UP_PERIOD_STAKE_ADDRESS =
  process.env.NEXT_PUBLIC_CHEER_UP_PERIOD_STAKE_ADDRESS;

export const CHEER_UP_BADGE_ADDRESS =
  process.env.NEXT_PUBLIC_CHEER_UP_BADGE_ADDRESS;

export const CHEER_UP_EMOJI_ADDRESS =
  process.env.NEXT_PUBLIC_CHEER_UP_EMOJI_ADDRESS;

export const CHEER_UP_EMOJI_MERGE_ADDRESS =
  process.env.NEXT_PUBLIC_CHEER_UP_EMOJI_MERGE_ADDRESS;

export const FPX_EMOJI_ADDRESS = process.env.NEXT_PUBLIC_FPX_EMOJI_ADDRESS;

console.log("contracts", {
  CHEER_UP_ADDRESS,
  CHEER_UP_BLIND_BOX_ADDRESS,
  CHEER_UP_PERIOD_STAKE_ADDRESS,
  // CHEER_UP_BADGE_ADDRESS,
  CHEER_UP_EMOJI_ADDRESS,
  CHEER_UP_EMOJI_MERGE_ADDRESS,
  FPX_EMOJI_ADDRESS,
});

export const OPENSEA_ORIGIN = process.env.NEXT_PUBLIC_OPENSEA_ORIGIN;
export const LOOKSRARE_ORIGIN = process.env.NEXT_PUBLIC_LOOKSRARE_ORIGIN;
export const ETHERSCAN_ORIGIN = process.env.NEXT_PUBLIC_ETHERSCAN_ORIGIN;

export enum EnumWallet {
  "None",
  "Metamask",
  "WalletConnect",
}

export const CHEERS_UP_VERIFY_URL = "https://www.premint.xyz/cheersup/verify/";

/** 阶段1：报名结束，筛选中 */
export const CHEERS_UP_STAGE_1_START_TIME =
  process.env.NEXT_PUBLIC_CHEERS_UP_STAGE_1_START_TIME;
/** 阶段2：筛选结束，公布结果 */
export const CHEERS_UP_STAGE_2_START_TIME =
  process.env.NEXT_PUBLIC_CHEERS_UP_STAGE_2_START_TIME;
/** 阶段3：公布Mint倒计时 */
export const CHEERS_UP_STAGE_3_START_TIME =
  process.env.NEXT_PUBLIC_CHEERS_UP_STAGE_3_START_TIME;
/** 阶段4：开售 */
export const CHEERS_UP_STAGE_4_START_TIME =
  process.env.NEXT_PUBLIC_CHEERS_UP_STAGE_4_START_TIME;

/** 阶段6：公布可Mint的Waitlist  */
export const CHEERS_UP_STAGE_6_START_TIME =
  process.env.NEXT_PUBLIC_CHEERS_UP_STAGE_6_START_TIME;
/** 阶段7：Waitlist Mint开售 */
export const CHEERS_UP_STAGE_7_START_TIME =
  process.env.NEXT_PUBLIC_CHEERS_UP_STAGE_7_START_TIME;
/** 阶段8：Waitlist Mint结束 */
export const CHEERS_UP_STAGE_8_START_TIME =
  process.env.NEXT_PUBLIC_CHEERS_UP_STAGE_8_START_TIME;

// cheers up start
export const CHEERS_UP_OPENSEA_URL = "https://opensea.io/collection/cheers-up";

export const CHEERS_UP_BLUR_URL = "https://blur.io/collection/cheers-up";

export const CHEERS_UP_X2Y2_URL = "https://x2y2.io/collection/cheers-up";

export const CHEERS_UP_LOOKSRARE_URL =
  "https://looksrare.org/collections/0x3113a3c04aebec2b77eb38eabf6a2257b580c54b";

export const CHEERS_UP_ELEMENT_URL =
  "https://element.market/collections/cheers-up";
// cheers up end

// cheers up period start
export const CHEERS_UP_PERIOD_OPENSEA_URL =
  "https://opensea.io/collection/cheers-up-period";

export const CHEERS_UP_PERIOD_BLUR_URL =
  "https://blur.io/collection/cheers-up-period";

export const CHEERS_UP_PERIOD_X2Y2_URL =
  "https://x2y2.io/collection/cheers-up-period";

export const CHEERS_UP_PERIOD_LOOKSRARE_URL =
  "https://looksrare.org/collections/0xa5bb28eecc6134f89745e34ec6ab5d5bcb16dad7";

export const CHEERS_UP_PERIOD_ELEMENT_URL =
  "https://element.market/collections/cheers-up-period";
// cheers up period end

export const CHEERS_UP_TWITTER_URL = "https://twitter.com/CheersUP_NFT";
export const CRYPTONATTY_TWITTER_URL = "https://twitter.com/CryptoNatty_io";

export const LAUNCH_TOWER_URL =
  process.env.NEXT_PUBLIC_LAUNCH_TOWER_URL ||
  "https://launchtower.cryptonatty.io";
