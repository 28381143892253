import { useSnackbar } from "notistack";
import { useState } from "react";
import { nftListApi } from "../utils/api";

export function useNftList() {
  const [nftListData, setNftListData] = useState({ items: undefined });
  const { enqueueSnackbar } = useSnackbar();

  function getFetcher(request) {
    return async () => {
      const { data, err } = await request();
      if (err) {
        if (err.response?.data?.code === 2) {
          location.href = "/api/logout";
        }
        enqueueSnackbar("Unable to connect to the network!", {
          variant: "error",
          preventDuplicate: true,
        });
        throw err;
      }
      return { data, err };
    };
  }

  const nftList = nftListData?.items;
  // const nftList = require("../mocks/nftList").default;

  const fetchNftList = async () => {
    const res = await getFetcher(nftListApi)();
    if (res.err) {
      return;
    }
    console.log("fetchNftList", res);
    setNftListData(res.data);
  };

  return {
    nftList,
    fetchNftList,
  };
}
