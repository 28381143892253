import React from "react";
import { FooterV2 } from "../FooterV2";
import Image from "next/legacy/image";
import ExplorerIcon from "../../public/img/index-v2/explorer.svg";
import RightIcon from "../../public/img/index-v2/right.svg";
import Link from "next/link";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import { copyTocontent } from "utils";
import { useSnackbar } from "notistack";
import { useTranslation } from "next-i18next";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(51, 51, 51, 0.8)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(51, 51, 51, 0.8)",
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    fontSize: "12px",
    height: "32px",
  },
}));

const Left = ({ profile, account, balance }) => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();

  const accountsub = account
    ? account.substring(0, 6) +
      "..." +
      account.substring(account.length - 4, account.length)
    : "";

  return (
    <div className=" bg-white rounded-[40px] w-[430px] mr-[120px] pt-[60px] px-[32px] pb-[36px] flex flex-col items-center justify-between text-black">
      {/* vertical */}
      {/* <div className="text-center">
        <Image
          width={128}
          height={128}
          src={`${location.protocol}${profile.avatarUrl}`}
          className={"rounded-full bg-[#ccc]"}
          priority
        />
        <div className="font-bold font-DIN_Alternate text-[48px] mt-[24px] mb-[4px] line-clamp-1">
          {profile.name}
        </div>
        <div className="font-light text-[18px] line-clamp-2">
          {profile.description}
        </div>
      </div> */}

      {/* horizontal */}
      <div className="flex w-full items-center">
        <Link passHref href="/collection">

          <Image
            width={120}
            height={120}
            src={`${location.protocol}${profile.avatarUrl}`}
            className={"rounded-full bg-[#E3E5E7]"}
            priority
          />

        </Link>
        <div className="flex-1 ml-[24px]">
          <div className="font-bold font-DIN_Alternate text-[48px] mb-[6px] line-clamp-1">
            {profile.name}
          </div>
          <div className="font-light text-[18px] leading-[26px] line-clamp-2">
            {profile.description}
          </div>
        </div>
      </div>

      <div className="mt-[80px] w-[366px] h-[200px] bg-center bg-no-repeat bg-contain bg-[url(/img/index-v2/wallet-bg.png)] relative">
        <div className="absolute top-[66px] left-[100px] text-[36px] font-DIN_Alternate">
          {balance ? (+balance).toFixed(4) : ""}
        </div>

        <span
          className={"text-[16px]"}
          onClick={() => {
            copyTocontent(account, () => {
              enqueueSnackbar("Copied successfully", { variant: "success" });
            });
          }}
        >
          <BootstrapTooltip title="Copy" placement="top">
            <button className="btn-unstyled absolute bottom-[36px] left-[31px] text-[12px] font-medium tracking-[0.06em] transition hover:opacity-80">
              {accountsub}
            </button>
          </BootstrapTooltip>
        </span>

        <Link passHref href={`https://etherscan.io/address/${account}`} target="_blank">

          <button className="btn-unstyled w-[212px] h-[48px] flex items-center justify-center absolute right-[-2px] bottom-[21px] text-white font-medium group">
            <div className="mr-[8px] transition group-hover:opacity-80">
              {t("8")}
            </div>
            <div className="w-[32px] h-[32px] rounded-full border border-solid border-white flex justify-center items-center transition  group-hover:bg-white">
              <ExplorerIcon className="transition text-white group-hover:text-black" />
            </div>
          </button>

        </Link>
      </div>
    </div>
  );
};

const LinkItem = ({ children }) => {
  return (
    <div className="relative w-full h-[120px] text-[26px] font-black menu-link-hover-line flex items-center border-solid border-0 border-b border-[rgba(255,255,255,0.1)] group">
      <div className="flex-1">{children}</div>
      <div className="opacity-0 translate-x-[-32px] group-hover:opacity-100 group-hover:translate-x-0 transition">
        <RightIcon />
      </div>
    </div>
  );
};

const Right = ({ onLogOutClick }) => {
  const { t } = useTranslation("common");

  return (
    <div className="flex-1">
      <Link passHref href="/profile">

        <LinkItem>{t("9")}</LinkItem>

      </Link>

      <Link passHref href="/points">

        <LinkItem>{t("15")}</LinkItem>

      </Link>

      <Link passHref href="/collection">

        <LinkItem>{t("10")}</LinkItem>

      </Link>

      <Link passHref href="/nft-auth">

        <LinkItem>{t("11")}</LinkItem>

      </Link>

      <button className="btn-unstyled w-full text-left" onClick={onLogOutClick}>
        <LinkItem>{t("12")}</LinkItem>
      </button>
    </div>
  );
};

export const MenuMask = ({ profile, account, balance, onLogOutClick }) => {
  return (
    <div className="fixed top-[100px] bottom-0 w-full animate-fadein z-[100] bg-black">
      <div className="absolute w-full top-0 bottom-[100px] text-white py-[60px] px-[120px]">
        <div className="h-full max-h-[938px] max-w-[1440px] mx-auto flex items-center justify-between">
          <Left profile={profile} account={account} balance={balance} />
          <Right onLogOutClick={onLogOutClick} />
        </div>
      </div>
      <FooterV2 footerFixed showWhiteLine />
    </div>
  );
};
