import PBadge from "../../public/img/index-v2/plain/badge.svg";
import PCup from "../../public/img/index-v2/plain/cup.svg";
import PEmoji from "../../public/img/index-v2/plain/emoji.svg";
import PMall from "../../public/img/index-v2/plain/mall.svg";
import PMeme from "../../public/img/index-v2/plain/MEME.svg";
import PPassTicket from "../../public/img/index-v2/plain/pass-ticket.svg";
import PRoadmap from "../../public/img/index-v2/plain/roadmap.svg";
import PLaunchTower from "../../public/img/index-v2/plain/launch-tower.svg";
import PCupPoints from "../../public/img/index-v2/plain/cup-points.svg";

import CBadge from "../../public/img/index-v2/color/badge.svg";
import CCup from "../../public/img/index-v2/color/cup.svg";
import CEmoji from "../../public/img/index-v2/color/emoji.svg";
import CMall from "../../public/img/index-v2/color/mall.svg";
import CMeme from "../../public/img/index-v2/color/MEME.svg";
import CPassTicket from "../../public/img/index-v2/color/pass-ticket.svg";
import CRoadmap from "../../public/img/index-v2/color/roadmap.svg";
import CLaunchTower from "../../public/img/index-v2/color/launch-tower.svg";
import CCupPoints from "../../public/img/index-v2/color/cup-points.svg";

import { FC, ReactNode } from "react";
import classNames from "classnames";
import { styled } from "@mui/system";

interface IColorLinkProps {
  className?: string;
  type?:
    | "cup"
    | "emoji"
    | "badge"
    | "mall"
    | "meme"
    | "pass-ticket"
    | "roadmap"
    | "launch-tower"
    | "cup-points";
  plainText?: ReactNode;
  colorText?: ReactNode;
  [x: string]: any;
}

const _ColorLink: FC<IColorLinkProps> = ({
  type = "",
  plainText = null,
  colorText = null,
  className = "",
  ...rest
}) => {
  let _plainText;
  let _colorText;

  switch (type) {
    case "cup":
      _plainText = <PCup />;
      _colorText = <CCup />;
      break;

    case "emoji":
      _plainText = <PEmoji />;
      _colorText = <CEmoji />;
      break;

    case "badge":
      _plainText = <PBadge />;
      _colorText = <CBadge />;
      break;

    case "mall":
      _plainText = <PMall />;
      _colorText = <CMall />;
      break;

    case "meme":
      _plainText = <PMeme />;
      _colorText = <CMeme />;
      break;

    case "pass-ticket":
      _plainText = <PPassTicket />;
      _colorText = <CPassTicket />;
      break;

    case "roadmap":
      _plainText = <PRoadmap />;
      _colorText = <CRoadmap />;
      break;

    case "launch-tower":
      _plainText = <PLaunchTower />;
      _colorText = <CLaunchTower />;
      break;

    case "cup-points":
      _plainText = <PCupPoints />;
      _colorText = <CCupPoints />;
      break;

    default:
      _plainText = plainText;
      _colorText = colorText;
      break;
  }

  return (
    <button
      className={classNames("btn-unstyled relative", className)}
      {...rest}
    >
      <div className="w-full bottom absolute transition duration-300">
        {_colorText}
      </div>
      <div className="front relative z-[1] transition duration-300 opacity-100">
        {_plainText}
      </div>
    </button>
  );
};

export const ColorLink = styled(_ColorLink)(() => {
  return {
    "&:hover .front": {
      transform: "translateY(-6px) rotateX(90deg)",
      opacity: 0,
    },
    ".bottom": {
      transform: "translateY(6px) rotateX(-90deg)",
      opacity: 0,
    },
    "&:hover .bottom": {
      transform: "translateY(0) rotateX(0)",
      opacity: 1,
    },
  };
});
