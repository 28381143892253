import Image from "next/legacy/image";
import loadingImg from "../../public/icon/loading.png";
import loadingBlackImg from "../../public/icon/loading-black.png";
import { FC } from "react";
import classNames from "classnames";

interface ILoadingProps {
  color?: "light" | "dark";
  className?: string;
  [k: string]: any;
}

export const Loading: FC<ILoadingProps> = ({
  color = "light",
  className = "",
  ...rest
}) => {
  return (
    <div
      className={classNames("w-[14px] h-[14px] relative", className)}
      {...rest}
    >
      <Image
        src={color === "light" ? loadingImg : loadingBlackImg}
        alt=""
        className="animate-[loading_1s_linear_infinite]"
        layout="fill"
        priority
      />
    </div>
  );
};
