/** 处理cheersup开盲盒合约报错 */
export const cheersupPeriodRevealErrorHandler = (
  errorString: string
): string[] | undefined => {
  console.log("cheersupPeriodRevealErrorHandler", errorString);

  if (typeof errorString !== "string") {
    return;
  }

  const errors = [
    [
      "reveal has not enabled",
      "Revealing has not started yet. Please follow our latest news.",
    ],
    [
      "caller is not owner",
      "Looks like you don’t have any Cheers UP Period. You could buy one on OpenSea.",
    ],
    ["insufficient funds", "Insufficient funds for reveal price and gas."],
    ["UNPREDICTABLE_GAS_LIMIT", "Insufficient funds for reveal price and gas."],
  ];

  for (let error of errors) {
    if (errorString.includes(error[0])) {
      return error;
    }
  }
};
